import React from "react";

import Grid from "@material-ui/core/Grid";

export default class SectionBuilder {
    static build(cards, pos, title, userLang, isColorTopic) {
        let titleGrids;
        let grids = [];
        let cardno = 0;
        let style = {};
        if (isColorTopic) {

            style = {
                paddingTop: 12,
                paddingBottom: 4
            }
        }

        let singleTitle =
            <Grid
                item xs={12}
                key={'gridpos' + pos + 'gridTitleSingle' + userLang}
                style={style}
            >
                {title}
            </Grid>;
        let doubleTitle =
            <Grid
                item xs={12}
                key={'gridpos' + pos + 'gridTitleDouble' + userLang}
                style={style}
            >
                {title}
            </Grid>;
        if(title == null) {
            singleTitle = null;
            doubleTitle = null;
        }
        titleGrids = [singleTitle, doubleTitle];
        for (let card of cards) {
            const single =
                <Grid
                    item xs={12}
                    key={'gridpos' + pos + 'grid' + cardno + userLang}
                >
                    {card}
                </Grid>;
            const double =
                <Grid
                    item xs={6}
                    key={'gridpos' + pos + 'grid' + cardno + userLang}
                >
                    {card}
                </Grid>;
            grids.push([single, double])
            cardno++;
        }
        return [titleGrids, grids];
    }
}
